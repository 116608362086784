import React, { useState } from 'react';
import '../styles/Raffle.css';
import masks from '../json/masks.json';
import { Network, Alchemy } from 'alchemy-sdk';

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

const SC = '0xb45d21dc0c1cf275f790b668c3ae09ac5677b740';

const settings = {
  apiKey: "LsleipXjq-6PLIgDxH5OYaTnqahvP5HV",
  network: Network.ETH_MAINNET,
};

const alchemy = new Alchemy(settings);
console.log("Made with ❤️ by RpGmAx - Below, the masks database used during the draw ! #transparency")
console.log(masks);

function Lottery() {

  const [state, setState] = useState({
    startRaffleMsg: "Lancer la loterie 🎭",
    isRaffleInProgress: false,
    randomMaskID: null
  });

  const getRandomMaskID = () => {
    
    const randomIndex = Math.floor(Math.random() * masks.length);
    const randomEntry = masks[randomIndex];
    console.log('Maybe #' + randomEntry.id + ' ?');
    return randomEntry.id;
   }; 

  async function startRaffle() {

    setState(prevState => ({ ...prevState, startRaffleMsg: "Loterie en cours 🎭", isRaffleInProgress: true, winnerWalletAddress: null }));

    try {

      const OS = "https://opensea.io/assets/ethereum/" + SC + "/";

      for (let i = 0; i < 20; i++) {
        setState(prevState => ({ ...prevState, randomMaskID: getRandomMaskID() }));
        await delay(150);
      }

      const winnerMaskID = getRandomMaskID();
      console.log('Yes ! #' + winnerMaskID + ' is the winner :)');

      setState(prevState => ({
        ...prevState,
        isRaffleInProgress: false,
        randomMaskID: winnerMaskID,
        winnerLink: `${OS}${winnerMaskID}`,
        startRaffleMsg: "Relancer la loterie 🎭"
      }));

      if (winnerMaskID) {
        const { owner, ownerMasksCount } = await getOwnerAndMasks(winnerMaskID);
    
        setState(prevState => ({
          ...prevState,
          winnerWalletAddress: owner,
          winnerMasksCount: ownerMasksCount,
        }));
      }

    } catch (error) { console.error(error); }
  }

  async function getOwnerAndMasks(winnerMaskID) {
    const owner = (await alchemy.nft.getOwnersForNft(SC, winnerMaskID))['owners'][0];
    const ownerMasks = await alchemy.nft.getNftsForOwner(owner, { contractAddresses: [SC] });

    return { owner, ownerMasksCount: ownerMasks.totalCount };
  }

  return (
    <div id="global">
      <img id="mask-img" src={(state.randomMaskID !== null && state.randomMaskID !== undefined) ? `https://assets.thecryptomasks.xyz/images/TheCryptomasksProject/${state.randomMaskID}.jpg` : '/images/airdrop.gif'} alt="Cryptomasks" />
      <div id="winner">
        {state.winnerLink && !state.isRaffleInProgress ? (
        
        <>👏 Félicitations au <a href={state.winnerLink} target="_blank" rel="noopener noreferrer">{`Cryptomasks #${state.randomMaskID}`}</a> qui remporte la loterie !<br />
        {state.winnerWalletAddress && (<small>Propriétaire : {state.winnerWalletAddress} - Holder de {state.winnerMasksCount} 🎭</small>)}
        
        </> ) : null }
      </div>
      <button id="startRaffle" onClick={startRaffle} disabled={state.isRaffleInProgress ? 'disabled' : ''}>{state.startRaffleMsg}</button>
    </div>
  );
}

export default Lottery;
